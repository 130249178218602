import logo from '../logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "../components/NavBar";
import { Banner } from "../components/Banner";
import { Skills } from "../components/Skills";
import { Projects } from "../components/Project";
import { Footer } from "../components/Footer";
import React from 'react';

export default function Home () {
  return (
    <div className="App">
      
      <Banner />
      <Projects />
    </div>

  );
}