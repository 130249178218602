import logo from './logo.svg';
import './App.css';
import { NavBar } from './components/NavBar';
import { Banner } from "./components/Banner";
import { Skills } from './components/Skills';
import { Projects } from './components/Project';
import { Footer } from './components/Footer';
import Home from './pages/index';
import Privacy from './pages/privacypolicy';
import Term from './pages/termcondition';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route,} from 'react-router-dom';

function App() {
  return (
    <div>
    <Router basename={process.env.PUBLIC_URL}>
      <NavBar />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/privacypolicy' element={<Privacy />} />
        <Route path='/termcondition' element={<Term />} />
      </Routes>
      <Footer />
    </Router>
  </div>
  );
}

export default App;
