import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import colorSharp2 from "../assets/img/color-sharp2.png";
import { ProjectCard } from "../components/ProjectCard";
import { Footer } from '../components/Footer';
import React from "react";


const Term = () => {
	return (
		<section className="privacy">
      <Container>
        <Row>
          <Col size={12}>
          <h2>Terms and Conditions</h2>

    <p>These Terms and Conditions ("Terms") govern your use of the Southbank Noir mobile application ("Application") provided by Southbank Noir. By downloading, accessing, or using the Application, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use the Application.</p>

    <h2>1. License</h2>
    <p>a. Subject to these Terms, Southbank Noir grants you a limited, non-exclusive, non-transferable, revocable license to use the Application for your personal, non-commercial use.</p>
    <p>b. You may not: (i) modify, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Application; (ii) distribute, sublicense, rent, lease, or lend the Application; (iii) use the Application for any unlawful purpose; (iv) remove or alter any copyright, trademark, or other proprietary rights notices from the Application.</p>

    <h2>2. User Accounts</h2>
    <p>a. In order to access certain features of the Application, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
    <p>b. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify Southbank Noir immediately of any unauthorized use of your account or any other breach of security.</p>

    <h2>3. Privacy</h2>
    <p>By using the Application, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.</p>

    <h2>4. Intellectual Property</h2>
    <p>a. All intellectual property rights in the Application and its content, including but not limited to copyrights, trademarks, and trade secrets, are owned by Southbank Noir or its licensors.</p>
    <p>b. You may not use, copy, reproduce, modify, distribute, or create derivative works of the Application or its content without the prior written consent of Southbank Noir.</p>

    <h2>5. Disclaimer of Warranties</h2>
    <p>a. The Application is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</p>
    <p>b. Southbank Noir does not warrant that the Application will be error-free, uninterrupted, secure, or free of viruses or other harmful components.</p>

    <h2>6. Limitation of Liability</h2>
    <p>To the fullest extent permitted by applicable law, Southbank Noir shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Application.</p>

    <h2>7. Governing Law</h2>
    <p>These Terms shall be governed by and construed in accordance with the laws of Indonesia, without regard to its conflict of law provisions.</p>

    <h2>8. Changes to Terms</h2>
    <p>Southbank Noir reserves the right to modify or revise these Terms at any time, and your continued use of the Application following any such changes constitutes your acceptance of the revised Terms.</p>

          </Col>
        </Row>

      </Container>
    </section>
	);
};

export default Term;
