import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import appstore from "../assets/img/get-our-apps-ios.png";
import playstore from "../assets/img/get-our-apps-android.png";
import waicon from '../assets/img/whatsapp.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            {/* <img src={logo} alt="Logo" /> */}
            <p>Southbank Noir</p>
            <p>Paskal Hyper Square, Blok O, </p>
            <p>Kb. Jeruk, Kec. Andir, Kota Bandung, Jawa Barat 40181</p>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
                  <a href="https://www.instagram.com/southbank.noir/"><img src={navIcon3} alt="" /></a>
                  <a href="#"><img src={navIcon2} alt="" /></a>
                  <a href="https://api.whatsapp.com/send?phone=0818283939" target='_blank'><img src={waicon} alt="" /></a>
            </div>
            <p>
            <a href="#"><img src={playstore} alt="" /></a> &nbsp;
              <a href="#"><img src={appstore} alt="" /></a></p>
             <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
